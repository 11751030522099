import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {observer} from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { useStores } from '../../../stores/hooks'
import Button from '../../common/Button'
import GramInput from '../../common/GramInput'
import { validateEmail } from '../../../validation'
import { useToasts } from 'react-toast-notifications'

function ForgotPassword() {
  const { accountStore } = useStores()
  const history = useHistory()
  const {addToast} = useToasts()

  const [email, setEmail] = useState('')

  const [formError, setFormError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (accountStore.isAuthenticated) {
      history.replace('/painel')
    }
  }, [accountStore, history])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target

    setFormError(validateEmail(value))
    setEmail(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if(!formError) {
      setIsLoading(true)

      accountStore.sendForgotPasswordEmail(email)
        .then(_ => addToast('Email de recuperação enviado.', {appearance: 'success'}))
        .catch(_ => addToast('Alguma coisa deu errado.', {appearance: 'error'}))
        .finally(() => setIsLoading(false))
      }
  }

  return (
    <Container>
      <Title>Para recuperar sua senha, digite seu email abaixo</Title>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <GramInput
            labelText="Email:"
            error={formError}
            onChange={handleOnChange}
            value={email}
            name="email"
            type="email"
          />
          <CustomButton isLoading={isLoading} type="submit">
            Enviar email de recuperação
          </CustomButton>
        </form>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 55px);
  padding-bottom: 40px;
  background-color: #faf9f9;
`

const FormContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  max-width: 420px;
  margin: 36px auto 0;
  padding: 16px;

  border: 1px solid #dcdcdc;
  border-radius: 4px;

  background-color: #fff;

  box-shadow: 2px 2px 3px 0px #efefef;
`

const Title = styled.h1`
  padding: 48px 10px 12px;
  margin: 0;

  line-height: 36px;
  font-size: 28px;

  text-align: center;
  color: #3c3c3c;
`

const CustomButton = styled(Button)`
  width: 100%;
`

export default observer(ForgotPassword)
