import React from 'react'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'

interface HeroProps {
  placeDetails: {
    image?: string
    title: string
    description: string
  }
}

function Hero (props: HeroProps) {
  const {placeDetails} = props
  return (
    <>
      <HeroWrapper image={placeDetails.image}>
        <HeroTitle>Bem-vindo</HeroTitle>
      </HeroWrapper>
      <TitleContainer>
        <Title>{placeDetails.title}</Title>
        <SubTitle>{placeDetails.description}</SubTitle>
      </TitleContainer>
    </>
  )
}

interface IHeroWrapperProps {
  image?: string
}

const HeroWrapper = styled.div<IHeroWrapperProps>`
  max-width: 100%;
  min-height: 250px;
  
  
  display: flex;
  align-items: flex-end;
  justify-content: center;

  background: linear-gradient(
    0deg, 
    rgba(0, 0, 0, 0.3), 
    rgba(0, 0, 0, 0.05)), 
    url(${props => props.image || ''}
  );

  background-position: center center;
  background-size: cover;

  color: #ffffff;

  
  ${media.upToMobL} {
    min-height: 220px;
  }

  ${media.deskLUp} {
    /* margin: 0 auto; */
    min-height: 340px;
    margin: 0 16px 0;
    border-bottom-right-radius: 28px;
    border-bottom-left-radius: 28px;
  }
`

const HeroTitle = styled.h1`
  margin: 0 16px;
  line-height: 50px;
  font-weight: bold;
  font-size: 62px;

  ${media.mobXS} {
    line-height: 38px;
    font-size: 48px;
  }

  ${media.mobS} {
    line-height: 38px;
    font-size: 52px;
  }

  ${media.mobM} {
    line-height: 48px;
    font-size: 60px;
  }

  ${media.deskLUp} {
    line-height: 74px;
    font-size: 92px;
  }
`

const TitleContainer = styled.div`
  max-width: 920px;
  padding: 22px 0;
  margin: 0 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border-bottom: 1px solid #D9D9D9;

  ${media.deskLUp} {
    padding: 32px 0;
    margin: 0 auto;
  }
`

const Title = styled.h1`
  margin: 0%;
  font-size: 1.8em;
  font-weight: 700;
`

const SubTitle = styled.p`
  color: #666;
`

export default Hero