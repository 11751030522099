import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import Hero from './Hero';
import Contact from './Contact';
import GeneralInfo from './GeneralInfo';
import Sections from './Section';
import Recommendations from './Recommendations';
import { IPlace } from '../../shared/places/interfaces';
import { useStores } from '../../stores/hooks';
import NotFound from '../NotFound';
import LoadingWrapper from '../common/LoadingWrapper';
import Helmet from 'react-helmet';
import { media } from '../../util/breakpoints';


export const getPlaceData = (pageId: string): IPlace => ({
  pageId,
  name: 'Quarto da Rita',
  description: 'O melhor quarto de São Paulo! Quarto duplo tranquilo com banheiro compartilhado no 2º andar com escadas. ',
  // image: 'https://a0.muscache.com/im/pictures/miso/Hosting-866674451502266698/original/0a047610-3d39-4316-b424-05309703f33a.jpeg?im_w=1200',
  image_url: 'https://a0.muscache.com/im/pictures/miso/Hosting-690442504956941333/original/d4c3a08b-dd66-4f0c-98b8-1aac5ce43c7a.jpeg?im_w=1200',
  contact: {
    name: 'Rita',
    phone: '11987654321',
    whatsapp: '11987654321',
    profileImage: 'https://a0.muscache.com/im/pictures/user/User-55996047/original/aa03ae82-6050-4dd3-a49b-1f78c5b237d9.jpeg?im_w=240'
  },
  general: {
    address: {
      street: 'Estrada de São Sebastião',
      number: '4',
      neighborhood: 'Boa Vista',
      city: 'Gonçalves',
      state: 'MG',
      postalCode: '',
      complement: 'km4',
    },
    wifi: {
      network: 'tomate_seco',
      pass: 'vidaverde2022'
    },
    checkin: {
      start: '13:00',
      end: '22:00',
      details: 'Digite o código 1234 no cofre da chave localizado ao lado da porta.'
    },
    checkout: {
      start: '11:00'
    }
  },
  sections: [
    {
      id: 1,
      title: 'Regras da Casa',
      content: '<ul><li><b>Política de Ruído:</b> Por favor, respeite os vizinhos e evite barulho após as 22:00.</li><li><b>Animais de Estimação:</b> Permitidos somente na área externa.</li><li><b>Fumantes:</b> Não é permitido fumar dentro da casa.</li></ul>',
      order_view: 0
    },
    {
      id: 2,
      title: 'Segurança',
      content: '<p>Saídas de Emergência: Porta principal e porta dos fundos.</p><p>Kit de Primeiros Socorros: Debaixo da pia do banheiro.</p>',
      order_view: 1
    },
    {
      id: 3,
      title: 'Comodidades',
      content: '<p>A privada funciona somente na terceira descarga.</p>',
      order_view: 2
    },
  ],
  recommendations: [
    {
      id: 1,
      title: 'Cachoreira Sete Quedas',
      type: 'place',
      phone: '',
      address: 'Estrada São Sebastião - 1,5km - Retiro, Gonçalves - MG',
      description: 'Entrar pelo sítio Boa Vista (segunda porteira depois do bar do Tinoco)',
      order_view: 0
    },
    {
      id: 2,
      title: 'Restaurante Mantiqueira',
      type: 'restaurant',
      phone: '+5511987654321',
      address: 'R. Joaquim Ferreira Souza, 37 - Goncalves, Gonçalves - MG',
      site: 'https://www.google.com.br/maps/place/Restaurante+Mantiqueira/@-22.6607577,-45.8583884,17z/data=!4m6!3m5!1s0x94cc750994697c1b:0x218810f4e7c9c011!8m2!3d-22.6608047!4d-45.8553889!16s%2Fg%2F11lh4xlnpt?entry=ttu',
      description: 'Aberto de seg. a sex. das 18:00 às 23:00',
      order_view: 1
    },
    {
      id: 3,
      title: 'Chale Gastrobar',
      type: 'bar',
      phone: '012996538077',
      whatsapp: '012996538077',
      address: 'Antonio caetano da rosa, 229 - centro, Gonçalves - MG, 37680-000',
      site: 'https://www.google.com.br/maps/place/Chale+Gastrobar/@-22.6607577,-45.8583884,17z/data=!4m6!3m5!1s0x94cc7527c1f47a83:0xc7a3f18ea32e83db!8m2!3d-22.6600604!4d-45.8547419!16s%2Fg%2F11fn1dy4qj?entry=ttu',
      description: 'CUPOM: VidaVerde2024',
      order_view: 2
    },
    {
      id: 4,
      title: 'Atelier Papegilla',
      type: 'shopping',
      phone: null,
      address: 'R. Cel João Viêira, 53 - Goncalves, Gonçalves - MG, 37680-000',
      description: 'Lindas artes em vidro',
      order_view: 3
    },
    {
      id: 5,
      title: 'São Mateus Supermercado',
      type: 'groceries',
      phone: '03536541321',
      whatsapp: '03536541321',
      address: 'R. Conselheiro Alfredo Pinto, 240, Gonçalves - MG, 37680-000',
      description: '',
      order_view: 4
    },
    {
      id: 6,
      title: 'Viva la Vida Café',
      type: 'night-club',
      phone: '',
      address: 'R. Conselheiro Alfredo Pinto, 240, Gonçalves - MG, 37680-000',
      description: '',
      order_view: 5
    },
    {
      id: 7,
      title: 'Renato Oliveira',
      type: 'guide',
      phone: '+5511987654321',
      whatsapp: '+5511987654321',
      address: null,
      description: 'Guia para passeios nas cachoeiras',
      order_view: 6
    },
  ]
})


function PlacePage() {
  const {pageId} = useParams<{pageId: string}>()

  const {placePageStore} = useStores()

  const [place, setPlace] = useState<IPlace>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchPlaceData = async (pageId: string) => {
      try {
        const data = await placePageStore.getPlaceData(pageId)
 
        setIsLoading(false)
        setPlace(data)
      } catch (error) {
        setIsLoading(false)
        return null
      }
    }

    fetchPlaceData(pageId)
  }, [pageId, placePageStore])

  if (place) {
    const placeDetails = {
      image: place.image_url || 'https://placehold.co/1200x700/FCDCDA/EE5048?text=...',
      title: place.name,
      description: place.description
    }
  
    return (
      <>
        <Helmet>
          <title>{place.name} | Hostli</title>
        </Helmet>
        <Page>
          <Hero placeDetails={placeDetails}/>
          <Wrapper>
            <GeneralInfo generalInfo={place.general}/>
            <Sections sections={place.sections}/>
            <Recommendations recommendations={place.recommendations}/>
            <Contact contact={place.general.contact}/>
          </Wrapper>
        </Page>
      </>
    )
  } else {
    if (isLoading) {
      return <LoadingWrapper isLoading={isLoading}><Page/></LoadingWrapper>
    } else {
      return <NotFound term={pageId}/>
    }
  }
}

const Page = styled.div`
  min-height: 100vh;
`

const Wrapper = styled.div`
  max-width: 952px;
  margin: 0 16px;
  padding-bottom: 16px;

  ${media.deskLUp} {
    margin: 0 auto;
  }
`

export default PlacePage
